exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".qAfaabVPED4oC_aFUyQeb{border:2px solid #d5d5d5;border-radius:50% !important;width:32px;height:32px;font-size:15px;margin-right:2px;margin-bottom:10px;margin-top:10px;font-weight:600;display:flex;flex-shrink:0;justify-content:center;align-items:center;background:#fff;color:#656565}@media(min-width: 360px){.qAfaabVPED4oC_aFUyQeb{width:32px;height:32px;font-size:15px;margin-bottom:10px;font-weight:600}}@media(min-width: 769px){.qAfaabVPED4oC_aFUyQeb{width:36px;height:36px;font-size:16px;margin-right:5px;margin-bottom:10px}}.bZ5XEIFTInnC2YOR2PXCl{background:#fff;color:#656565;border:2px solid #239a48;box-shadow:0 3px 6px #262900 38}._3t7kCNTZ5FIUW5cfto7OVM{background:#fff;color:#662d91;font-weight:600}._1vNo_A5msyrPO_m6H14FTg{display:flex;flex-wrap:wrap}.B1_TZvRnUR_kfRVMagmSz{justify-content:center}", ""]);

// Exports
exports.locals = {
	"betNumber": "qAfaabVPED4oC_aFUyQeb",
	"betNumberWon": "bZ5XEIFTInnC2YOR2PXCl",
	"betNumberBright": "_3t7kCNTZ5FIUW5cfto7OVM",
	"betNumbers": "_1vNo_A5msyrPO_m6H14FTg",
	"betNumbersCentered": "B1_TZvRnUR_kfRVMagmSz"
};