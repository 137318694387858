exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._11UaXZI4fMNrVOsdzSEiae{display:flex;justify-content:center;padding:0 6px}._11UaXZI4fMNrVOsdzSEiae ._3vO3e7jKmTg00PpwuvYquJ{display:flex;flex-direction:column;align-items:center;margin:0 .3em}._11UaXZI4fMNrVOsdzSEiae ._3Xj15KcmbFBGDMud0Ld_7h{color:#662d91;font-size:11px}._11UaXZI4fMNrVOsdzSEiae ._3vO3e7jKmTg00PpwuvYquJ ._3XNqnJgOiuM-gW_v5V12Sh{font-size:16px;font-weight:600}", ""]);

// Exports
exports.locals = {
	"countdownContainer": "_11UaXZI4fMNrVOsdzSEiae",
	"countdownItem": "_3vO3e7jKmTg00PpwuvYquJ",
	"countdownItemTxt": "_3Xj15KcmbFBGDMud0Ld_7h",
	"countdownTime": "_3XNqnJgOiuM-gW_v5V12Sh"
};