exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2ODNwrB4dKdANE0gdK_sx_{padding:2em 1em;padding:2em 1em 1em 1em}._1xFhoIBauL1GeH3rC-AJ2o{font-size:1em;font-weight:700;color:#7fbc35;margin:2em 0;text-align:center}@media(min-width: 769px){._1xFhoIBauL1GeH3rC-AJ2o{margin:2em 4em 1em 4em}}._1pevlXjevWSQdQQEkKB7lg{color:#e61723}._2wsjL2otoP5eu8h7_CHPPr{text-align:center;color:#662d91;margin-top:.5em}.uY-_vRKk_xeCYZa0-vWyy{color:#662d91;font-weight:700;font-size:1em;cursor:pointer;margin-top:.5em}._2lmc4ivdfiWJ2Z5TOYVNC5{position:absolute;width:92px;height:92px;top:-46px;left:calc(50% - 46px)}._1Qfof-lf55CL7guJFGCJf-{display:flex;justify-content:space-around;align-items:center}._2ODNwrB4dKdANE0gdK_sx_ hr{border:0;height:1px;background:#c3c3c3}._3lH6iMWbm-JNMgUKPs49zk{font-weight:700;font-size:1em;margin:2em auto;cursor:pointer}._2sErmXtX-99tFjMQbkWXQO{display:flex;justify-content:center;width:100%;align-items:center;flex-wrap:wrap}._1j7K3IcGt540txX3Uf8iNI{margin:0;padding:10px 6px;background:#662d91;color:#fff;min-width:140px;margin-left:8px;margin-right:8px;margin-top:3px}._1JRHEe3lWcz_eo0c0y2td6{color:#fff;position:absolute;top:-7px;left:-6px;border:1px solid #662d91;border-radius:100% !important;width:30px;height:30px;cursor:pointer;text-align:center;font-size:19px;background:#662d91}._2nfDgnyHh5nsvR2A-q8vKK::before{content:\"✖\"}", ""]);

// Exports
exports.locals = {
	"gameSummaryModal": "_2ODNwrB4dKdANE0gdK_sx_",
	"gameSummaryModalMainText": "_1xFhoIBauL1GeH3rC-AJ2o",
	"gameSummaryModalMainTextError": "_1pevlXjevWSQdQQEkKB7lg",
	"gameSummaryModalSecondaryText": "_2wsjL2otoP5eu8h7_CHPPr",
	"gameSummaryModalTextCb": "uY-_vRKk_xeCYZa0-vWyy",
	"gameSummaryModalIcon": "_2lmc4ivdfiWJ2Z5TOYVNC5",
	"gameSummaryModalBtnWrapper": "_1Qfof-lf55CL7guJFGCJf-",
	"gameSummaryModalCallToAction": "_3lH6iMWbm-JNMgUKPs49zk",
	"modalButtons": "_2sErmXtX-99tFjMQbkWXQO",
	"buttonModalAction": "_1j7K3IcGt540txX3Uf8iNI",
	"buttonCloseModal": "_1JRHEe3lWcz_eo0c0y2td6",
	"closeIcon": "_2nfDgnyHh5nsvR2A-q8vKK"
};