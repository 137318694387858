exports = module.exports = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._l6vo2fufZb3utrHp2PAm{background:#f5f5f5;color:#868686;border:1px solid #fff;font-size:12px;font-weight:600;margin:2px 2.5px;padding:1.5px 2px;text-align:center;cursor:pointer;border-radius:4px !important}@media(min-width: 769px){._l6vo2fufZb3utrHp2PAm:hover{background-color:#662d91;color:#fff;border:1px solid transparent}}.rJMCCFrllDq0-HAGh5098{width:calc(14.28% - 5.1px)}@media(min-width: 769px){.rJMCCFrllDq0-HAGh5098{width:calc(14.28% - 5.1px)}}.ggh6ToOTLDFozWcwAjTor{width:calc(16.67% - 5.1px)}._1scwEho2DEt1ymd9AZzXXi{width:calc(20% - 5.1px)}._3M8TUGrJAw0kEbQuBSGHq8{background:#662d91;color:#fff;border:1px solid #662d91}", ""]);

// Exports
exports.locals = {
	"number": "_l6vo2fufZb3utrHp2PAm",
	"numberW7": "rJMCCFrllDq0-HAGh5098",
	"numberW6": "ggh6ToOTLDFozWcwAjTor",
	"numberW5": "_1scwEho2DEt1ymd9AZzXXi",
	"numberActive": "_3M8TUGrJAw0kEbQuBSGHq8"
};